import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
// eslint-disable-next-line import/no-unresolved
import { MDXProvider } from 'solid-mdx';
import { cache, createAsync, useLocation } from '@solidjs/router';
import { Heading, Link, Picture, Tag, TextLink } from '@troon/ui';
import { Show, Suspense } from 'solid-js';
import { IconChevronRight } from '@troon/icons';
import { isServer, NoHydration } from 'solid-js/web';
import { Meta, Title } from '@solidjs/meta';
import { Content } from '../../../components/content';
import { getConfigValue } from '../../../modules/config';
import { getReleaseServer } from './api/[release]';
import type { Release } from './api/[release]';
import type { ComponentProps, ParentProps } from 'solid-js';
import type { RouteSectionProps } from '@solidjs/router';

dayjs.extend(utc);
dayjs.extend(tz);

export default function PressReleasePage(props: RouteSectionProps) {
	const loc = useLocation();
	const data = createAsync(() => getRelease(loc.pathname.replace(/\/$/, '').split('/').at(-1) ?? ''), {
		deferStream: true,
	});

	return (
		<>
			<NoHydration>
				<Suspense>
					<Show when={data()}>
						{(data) => (
							<>
								<Title>{data().title} | Troon</Title>
								<Meta name="description" content={data().title} />
							</>
						)}
					</Show>
				</Suspense>
				<Content class="pb-16">
					<ul class="flex items-center gap-3 text-sm">
						<li>
							<Link href="/press-releases" class="text-nowrap">
								Press Releases
							</Link>
						</li>
						<Suspense>
							<Show when={data()}>
								<li aria-hidden>
									<IconChevronRight />
								</li>
								<li class="max-w-sm truncate">{data()?.title}</li>
							</Show>
						</Suspense>
					</ul>
				</Content>
				<Content size="small" class="flex flex-col gap-8">
					<Tag class="text-sm">Press release</Tag>
					<Heading as="h1">
						<Suspense>
							<Show when={data()}>{(data) => <>{data()?.title}</>}</Show>
						</Suspense>
					</Heading>
					<time class="text-lg">{dayjs(data()?.date, 'America/Phoenix').format('MMMM D, YYYY')}</time>
				</Content>
				<div class="container mx-auto my-8 max-w-screen-lg sm:px-6 md:px-12">
					<Picture
						src={`${getConfigValue('IMAGE_HOST')}/digital/press-releases/${data()?.image}`}
						sizes={[
							[480, 270],
							[1024, 576],
						]}
						class="w-full sm:rounded"
						alt=""
						crop="face"
					/>
				</div>
				<Content size="small" class="flex flex-col gap-8">
					<MDXProvider components={components}>{props.children}</MDXProvider>
				</Content>
			</NoHydration>
		</>
	);
}

const getRelease = cache(async (slug: string) => {
	if (!slug) {
		return null;
	}
	if (isServer) {
		return getReleaseServer(slug);
	}
	const res = await fetch(`https://${getConfigValue('HOST')}/press-releases/api/${slug}`);
	if (!res.ok) {
		throw new Error('Unable to load release');
	}
	return res.json() as Promise<Release>;
}, 'get-press-release');

const components = {
	h1: (props: ParentProps) => <Heading as="h1" {...props} />,
	h2: (props: ParentProps) => <Heading as="h2" {...props} />,
	h3: (props: ParentProps) => <Heading as="h3" {...props} />,
	h4: (props: ParentProps) => <Heading as="h4" {...props} />,
	h5: (props: ParentProps) => <Heading as="h5" {...props} />,
	h6: (props: ParentProps) => <Heading as="h6" {...props} />,
	p: (props: ParentProps) => <p {...props} />,
	a: (props: ComponentProps<typeof TextLink>) => <TextLink {...props} />,
	ul: (props: ParentProps) => <ul class="flex list-disc flex-col gap-4" {...props} />,
	li: (props: ParentProps) => <li class="ms-8" {...props} />,
	ol: (props: ParentProps) => <ol class="flex list-decimal flex-col gap-4" {...props} />,
};
